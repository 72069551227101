jQuery(window).load( function( $ ) {
    /* Ladeicon bei Seitenaufruf */
    /*jQuery(".loading-wrap").addClass('active');*/
    /*******************************/
});

jQuery(document).ready( function( $ ) {

    var myEle = document.getElementById("congrats");
    if(myEle){
        

        var count = 200;
        var defaults = {
            origin: { y: 0.7 }
        };

        function fire(particleRatio, opts) {
            confetti(Object.assign({}, defaults, opts, {
                particleCount: Math.floor(count * particleRatio)
            }));
        }
        var colors = ['#91273c', '#ffffff'];
        fire(0.25, {
            spread: 26,
            startVelocity: 55,
            colors: colors
        });
        fire(0.2, {
            spread: 60,
            colors: colors
        });
        fire(0.35, {
            spread: 100,
            decay: 0.91,
            scalar: 0.8,
            colors: colors
        });
        fire(0.1, {
            spread: 120,
            startVelocity: 25,
            decay: 0.92,
            scalar: 1.2,
            colors: colors
        });
        fire(0.1, {
            spread: 120,
            startVelocity: 45,
            colors: colors
        });
    }


    /* Ladeicon bei Seitenaufruf Lottie-einbindung */
    var logoParams = {
        container: document.getElementById('loadingLogo'),
        renderer: 'svg',
        loop: true,
        autoplay: true,
        animationData: loadingLogo
    };
    var loading;
    loading = lottie.loadAnimation(logoParams);
    loading = lottie.play();
    /*******************************/

    /* Suchenicon */
    var suchenButtonParams = {
        container: document.getElementById('suchenButton'),
        renderer: 'svg',
        loop: false,
        autoplay: false,
        animationData: suchenButton
    };
    var suchenButtonIcon;
    suchenButtonIcon = lottie.loadAnimation(suchenButtonParams);
    suchenButtonIcon = lottie.stop();
    /*******************************/

    /* upIcon */
    var upButtonParams = {
        container: document.getElementById('upButtonIcon'),
        renderer: 'svg',
        loop: false,
        autoplay: false,
        animationData: upButton
    };
    var upButtonIcon;
    upButtonIcon = lottie.loadAnimation(upButtonParams);
    upButtonIcon = lottie.stop();
    /*******************************/

    /* downIcon */
    var downButtonParams = {
        container: document.getElementById('downButtonIcon'),
        renderer: 'svg',
        loop: false,
        autoplay: false,
        animationData: downButton
    };
    var downButtonIcon;
    downButtonIcon = lottie.loadAnimation(downButtonParams);
    downButtonIcon = lottie.stop();
    /*******************************/

    /* Downloadicon */
    var downloadAnimParams = {
        container: document.getElementById('downloadAnim'),
        renderer: 'svg',
        loop: false,
        autoplay: false,
        animationData: downloadAnim
    };
    var downloadIcon;
    downloadIcon = lottie.loadAnimation(downloadAnimParams);
    downloadIcon = lottie.stop();
    /*******************************/

    $(".nav-suche").mouseover(function () {
        $("#suchenButton").addClass("active");
        $(".search-input").addClass("active");
    });

    $(".nav-suche").mouseleave(function () {
        setTimeout(function() {
            $("#suchenButton").removeClass("active");
            $(".search-input").removeClass("active");
        }, 3000);
    });

    $(".lines").click(function() {
        if($('.nav-icon').hasClass('active')){
            $('.nav-icon').removeClass("active");
            $('.sea').removeClass("active");
            $('.nav-icon-to-top').removeClass("active");
            $(this).parents(".mo-navigation-wrapper").find('.mo-navigation').removeClass("active");

        }
        else{
            $('.nav-icon').addClass("active");
            $('.nav-icon-to-top').addClass("active");
        }

    });

    $(".sea").click(function() {
        if($(this).hasClass('active')){
            $(this).removeClass("active");
            $(this).parents(".mo-navigation-wrapper").find('.mo-navigation').removeClass("active");
        }
        else{
            $(this).addClass("active");
            $(this).parents(".mo-navigation-wrapper").find('.mo-navigation').addClass("active");
        }

    });

    //One Screen Scroll
    $('.one-screen-down').click(function() {
        $('html, body').animate({
            scrollTop: $(this).offset().top
        }, 500, function() {});
    });

    $('.nav-icon-to-top').click(function() {
        upButtonIcon = lottie.play();
        $('html, body').animate({scrollTop: $('html, body').offset().top}, 500, function() {});
    });

    $('#downloadAnim').hover(function(){
        lottie.setDirection(1);
        lottie.play();
    }, function(){
        lottie.setDirection(-1);
        lottie.play();
    });

    /*******************************/

    /* Gleiche Höhe Titel/Content der Archivauflistung */
    /*
    function equalContentHeight() {
        var largest = 0;
        $(".post-headline").each(function (index) {
            console.log(index + ": " + $(this).height());
            var findHeight = $(this).height();
            if (findHeight > largest) {
                largest = findHeight;
            }
        });
        $(document).find(".post-headline").css({"height": largest + "px"});
        $(document).find(".post-headline h3").css({"height": largest + "px"});

        largest = 0;
        $(".archive.post-intro").each(function (index) {
            console.log(index + ": " + $(this).height());
            var findHeight = $(this).height();
            if (findHeight > largest) {
                largest = findHeight;
            }
        });
        $(document).find(".archive.post-intro").css({"height": largest + "px"});
    }
    equalContentHeight();
    */

    /*******************************/


    /* Ajax - Weitere Posts Laden - Archiv  */
    var indx; // Archiv Index
    var ppp = 3; // Posts Per Page
    var cat; // Kategorie
    var pn; // Seitennummer

    $(".more_posts").on("click",function(){ // When btn is pressed.
        that = $(this);
        clickable = parseInt($(this).data('clickable'));
        cat = $(this).data('category');
        pn = $(this).data('pagenumber');
        console.log("Kategorie ID: "+cat);
        console.log("Pagenumber ID: "+pn);
        $(this).attr("disabled",true); // Disable the button, temp.
        pn++;
        load_posts();
        $(this).data('pagenumber',pn);
        indx = $(this).parents(".counter").index();
        setTimeout(function(){

            }, 1000);

        $('html, body').delay(1000).animate({scrollTop: that.offset().top}, 500, function() {});
        if(clickable < 2){that.addClass('deactivated');}
        else{that.data('clickable',clickable-1);}

    });

    function load_posts(){
        var str = '&cat=' + cat + '&pn=' + pn + '&ppp=' + ppp + '&action=more_post_ajax';
        $.ajax({
            type: "POST",
            dataType: "html",
            url: '/wp-admin/admin-ajax.php',
            data: str,
            context: this,
            success: function(data){
                //$(".loading-wrap").removeClass('active');
                var $data = $(data);
                if($data.length){
                    that.prev().append($data);
                } else{
                }
            },
            error : function(jqXHR, textStatus, errorThrown) {}

        });
        return false;
    }
    /*******************************/

    /* Wenn eine ".archive.wrapper" Im sichtbaren Bereich des Fensters ist */
    $.fn.isInViewport = function() {
        var elementTop = $(this).offset().top;
        var elementBottom = elementTop + $(this).outerHeight();

        var viewportTop = $(window).scrollTop();
        var viewportBottom = viewportTop + $(window).height();

        return elementBottom > viewportTop && elementTop < viewportBottom;
    };

    $(window).on('resize scroll', function() {
        $('.archive.wrapper').each(function() {
            $that = $(this);
            if ($(this).isInViewport()) {
                $that.addClass("inview");
            } else {
                //$(".single-post-loader").html("");
            }
        });
    });
    /*******************************/

    /* Ajax Post in Container laden. */
    var post_id;

    $('body').on('click', '.load_post', function() {
/*
        var target = $(this);
        setTimeout(function(){
            $('html, body').animate({
                scrollTop: target.offset().top
            }, 500, function() {});
        }, 1000);

        console.log($(this).index());


        if($(this).index() % 3 > 1){
            $(this).prev().addClass('hide-post');
            $(this).prev().prev().addClass('hide-post');
        }
        if($(this).index() % 3 == 1){
            $(this).prev().addClass('hide-post');
            $(this).next().addClass('hide-post');
        }
        if($(this).index() % 3 < 1){
            $(this).next().addClass('hide-post');
            $(this).next().next().addClass('hide-post');
        }


        $(this).parents(".ajax-posts").find('.archive.wrapper').not(this).addClass('hide-post');

        $(this).parent(".ajax-posts").addClass('remove-pad');
        $(this).find(".archive.date").addClass('hide-date');
        $(this).find(".archive.item").addClass('hide-padding-item');
        $(this).find(".post-img").addClass('gain-height');

        //$(this).find(".archive.post").slideUp();

        setTimeout(function(){
            target.find('.single-post-loader').addClass('loaded');
            $(".archive.title").slideUp();
            $(".more_posts").slideUp();
            $(".jumper").slideUp();
        }, 1500);
        */

        //$(".loading-wrap").addClass('active');
        post_id = $(this).data('post_id');
        console.log("Post ID: "+post_id);

        var str = '&post_id=' + post_id + '&action=test';
        $that = $(this);
        $.ajax({
            type: 'POST',
            url: '/wp-admin/admin-ajax.php',
            data: str,
            success: function (data) {
                //$(".loading-wrap").removeClass('active');
                $([document.documentElement, document.body]).animate({
                    scrollTop: $that.offset().top
                }, 1000);
                $that.find(".single-post-loader").html(data);

                console.log("Success");
            },
            complete: function(){
                console.log("Complete");
            },
            error:function () {
                console.log("Error");
            }
        });

    });
    /*******************************/

    /* Ajax - Posts Laden - Animationen */
    /*
    $('body').on('click', '.archive.wrapper', function() {

        var target = $(this);
        setTimeout(function(){
            $('html, body').animate({
                scrollTop: target.offset().top
            }, 500, function() {});
        }, 1000);

        console.log($(this).index());


        if($(this).index() % 3 > 1){
            $(this).prev().addClass('hide-post');
            $(this).prev().prev().addClass('hide-post');
        }
        if($(this).index() % 3 == 1){
            $(this).prev().addClass('hide-post');
            $(this).next().addClass('hide-post');
        }
        if($(this).index() % 3 < 1){
            $(this).next().addClass('hide-post');
            $(this).next().next().addClass('hide-post');
        }


        $(this).parents(".ajax-posts").find('.archive.wrapper').not(this).addClass('hide-post');

        $(this).parent(".ajax-posts").addClass('remove-pad');
        $(this).find(".archive.date").addClass('hide-date');
        $(this).find(".archive.item").addClass('hide-padding-item');
        $(this).find(".post-img").addClass('gain-height');

        //$(this).find(".archive.post").slideUp();

        setTimeout(function(){
            target.find('.single-post-loader').addClass('loaded');
            $(".archive.title").slideUp();
            $(".more_posts").slideUp();
            $(".jumper").slideUp();
        }, 1500);

    });
    /*******************************/

    // Select all links with hashes
    $('a[href*="#"]')
        // Remove links that don't actually link to anything
        .not('[href="#"]')
        .not('[href="#0"]')
        .click(function(event) {
            // On-page links
            if (
                location.pathname.replace(/^\//, '') == this.pathname.replace(/^\//, '')
                &&
                location.hostname == this.hostname
            ) {
                // Figure out element to scroll to
                var target = $(this.hash);
                target = target.length ? target : $('[name=' + this.hash.slice(1) + ']');
                // Does a scroll target exist?
                if (target.length) {
                    // Only prevent default if animation is actually gonna happen
                    event.preventDefault();

                    $('html, body').animate({
                        scrollTop: target.offset().top
                    }, 500, function() {
                        // Callback after animation
                        // Must change focus!
                        var $target = $(target);
                        $target.focus();
                        if ($target.is(":focus")) { // Checking if the target was focused
                            return false;
                        } else {
                            $target.attr('tabindex','-1'); // Adding tabindex for elements not focusable
                            $target.focus(); // Set focus again
                        };
                    });
                }
            }
        });
    /*******************************/

});
